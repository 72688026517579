const getField = (result, queryName, connectionField) => (connectionField
  ? result[connectionField]?.[queryName]
  : result[queryName]);

const loadMore = ({ variables }) => (
  data,
  queryName,
  fetchMore,
  connectionField = null,
) => fetchMore({
  variables: {
    after: connectionField
      ? data[connectionField][queryName].pageInfo.endCursor
      : data[queryName].pageInfo.endCursor,
    ...variables,
  },

  updateQuery: (previousResult, { fetchMoreResult }) => {
    const previousField = getField(previousResult, queryName, connectionField);
    const {
      edges: newsEdges,
      pageInfo,
    } = getField(fetchMoreResult, queryName, connectionField);

    const { __typename, edges } = previousField;

    return connectionField
      ? {
        [connectionField]: {
          ...data[connectionField],
          [queryName]: {
            __typename,
            edges: [...edges, ...newsEdges],
            pageInfo,
          },
        },
      }
      : {
        [queryName]: {
          __typename,
          edges: [...edges, ...newsEdges],
          pageInfo,
        },
      };
  },
});

export default loadMore;
