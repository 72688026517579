import React, { ComponentProps, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import MuiSelect from '@mui/material/Select';
import MuiFormControl from '@mui/material/FormControl';
import MuiInputLabel from '@mui/material/InputLabel';
import { Controller } from 'react-hook-form';

const InputLabel = ({ ...properties }) => <MuiInputLabel {...properties} />;

const selectPropertyTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  inputProps: PropTypes.shape({
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  notched: PropTypes.bool,
  multiple: PropTypes.bool,
  input: PropTypes.element,
  children: PropTypes.node,
  renderValue: PropTypes.func,
  MenuProps: PropTypes.shape({}),
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

type SelectProperties = InferProps<
typeof selectPropertyTypes
> & {
  multiple?: boolean;
  input?: React.ReactElement;
  renderValue?: (
    (value: string[]) => React.ReactNode
  ) | undefined;
};

const Select = React.forwardRef(({
  label,
  value,
  inputProps,
  notched,
  id,
  onChange,
  multiple,
  input,
  children,
  renderValue,
  MenuProps,
  name,
  placeholder,
  ...properties
}: SelectProperties, reference) => (
  <MuiSelect
    native
    label={label}
    inputProps={{
      ...inputProps,
      label,
      'aria-label': label ?? undefined,
    }}
    notched={notched ?? undefined}
    id={id ?? undefined}
    onChange={onChange ?? undefined}
    ref={reference}
    input={input}
    renderValue={renderValue}
    MenuProps={MenuProps ?? undefined}
    multiple={multiple}
    value={value as string[] | '' | undefined}
    name={name ?? undefined}
    placeholder={placeholder ?? undefined}
    {...properties}
  >
    {children}
  </MuiSelect>
));

Select.propTypes = selectPropertyTypes;

const optionPropertyTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

type OptionProperties = InferProps<
typeof optionPropertyTypes
>;

const Option = (
  { ...properties }: OptionProperties,
) => <option {...properties} />;

const optionsPropertyTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

type OptionsProperties = InferProps<
typeof optionsPropertyTypes
>;

const Options = ({ options }: OptionsProperties) => (
  <>
    {options.map(
      ({ value, label }) => (
        <Option
          value={value}
          key={value}
        >
          {label}
        </Option>
      ),
    )}
  </>
);

Options.propTypes = optionsPropertyTypes;

const FormControl = ({ ...properties }) => <MuiFormControl {...properties} />;

const selectWithControlBasePropertyTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  shrinkLabel: PropTypes.bool,
  multiple: PropTypes.bool,
  input: PropTypes.node,
  renderValue: PropTypes.func,
  MenuProps: PropTypes.shape({}),
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

const selectWithFormControlPropertyTypes = {
  ...selectWithControlBasePropertyTypes,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

type SelectWithFormControlProperties = InferProps<
typeof selectWithFormControlPropertyTypes
> & {
  multiple?: boolean;
  input?: React.ReactElement;
  renderValue?: (
    (value: string[]) => React.ReactNode
  ) | undefined;
};

const SelectWithFormControl = React.forwardRef(({
  variant,
  fullWidth,
  id,
  shrinkLabel,
  label,
  value,
  options,
  onChange,
  multiple,
  input,
  renderValue,
  MenuProps,
  name,
  placeholder,
}: SelectWithFormControlProperties, reference) => (
  <FormControl
    variant={variant}
    fullWidth={fullWidth}
  >
    <InputLabel
      htmlFor={id}
      shrink={shrinkLabel}
    >
      {label}
    </InputLabel>
    <Select
      notched={shrinkLabel}
      id={id}
      label={label}
      value={value ?? ''}
      ref={reference}
      onChange={onChange}
      multiple={multiple}
      input={input}
      renderValue={renderValue}
      MenuProps={MenuProps}
      name={name}
      placeholder={placeholder}
    >
      <Options options={options} />
    </Select>
  </FormControl>
));

const selectWithControllerPropertyTypes = {
  ...selectWithControlBasePropertyTypes,
  rules: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
    ),
  ]),
};

type SelectWithControllerProperties = InferProps<
typeof selectWithControllerPropertyTypes
> & {
  control: ComponentProps<typeof Controller>['control'];
  rules: ComponentProps<typeof Controller>['rules'];
  multiple?: boolean;
  input?: React.ReactElement;
  renderValue?: (
    (value: string[]) => React.ReactNode
  ) | undefined;
};

const SelectWithController = ({
  rules,
  name,
  control,
  defaultValue,
  variant,
  fullWidth,
  id,
  shrinkLabel,
  label,
  options,
  multiple,
  input,
  renderValue,
  MenuProps,
  placeholder,
}: SelectWithControllerProperties) => {
  const render = useCallback(
    ({
      onChange, value, ref,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      value: string;
      // eslint-disable-next-line react/no-unused-prop-types
      ref: React.RefObject<HTMLInputElement>;
      // eslint-disable-next-line react/no-unused-prop-types
      onChange: (values: Record<string, unknown>) => void;
    }) => (
      <SelectWithFormControl
        onChange={onChange}
        value={value}
        ref={ref}
        variant={variant}
        fullWidth={fullWidth}
        id={id}
        shrinkLabel={shrinkLabel}
        label={label}
        options={options}
        multiple={multiple}
        input={input}
        renderValue={renderValue}
        MenuProps={MenuProps}
        name={name}
        placeholder={placeholder}
      />
    ),
    [
      variant,
      fullWidth,
      id,
      shrinkLabel,
      label,
      options,
      multiple,
      input,
      renderValue,
      MenuProps,
      name,
      placeholder,
    ],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={render}
    />
  );
};

SelectWithController.propTypes = selectWithControllerPropertyTypes;

export {
  SelectWithController, Select, Option, Options, FormControl, InputLabel,
};
